.audit-section {
    color: #ffffff;
    background-color: #000000;
    padding: 50px;
  }
  
  .audit-header {
    text-align: left;
    margin-bottom: 20px;
  }
  
  .audit-header h2 {
    color: #4676FF;
    font-size: 28px;
  }
  
  .audit-description {
    color: #888888;
    font-size: 16px;
  }
  
  .audit-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .audit-card {
    background-color: #1A1A1A;
    border-radius: 10px;
    padding: 20px;
    color: #ffffff;
    font-size: 16px;
    text-align: left;
    min-height: 150px;
  }
  
  .audit-card h3 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .audit-card ul {
    padding-left: 0;
    list-style: none;
  }
  
  .audit-card ul li {
    margin: 5px 0;
    display: flex;
    align-items: center;
  }
  
  .bullet {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .bullet.blue { background-color: #4676FF; }
  .bullet.orange { background-color: #FFA500; }
  .bullet.red { background-color: #FF4500; }
  .bullet.grey { background-color: #A9A9A9; }
  
  .blue-gradient {
    background: linear-gradient(135deg, #2b80ff, #00ccff);
  }
  
  .trusted-by img {
    max-height: 50px;
  }
  
  .carousel .control-dots {
    display: none;
  }
  