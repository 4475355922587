body,
html {
    background-color: #0e0e0f;
    color: white;
    overflow-x: hidden;
}
.container {
    max-width: 1340px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}

.homepage-hero {
    position: relative;
    height: auto; /* Full-screen height */
    display: flex;
    justify-content: flex-start; /* Content stays on the left */
    align-items: center;
    background-color: #000; /* Fallback color */
    overflow: hidden;
}

.homepage-hero::before {
    content: "";
    position: absolute;
    top: 20%; /* Adjusted for proper alignment */
    right: 0; /* Image remains on the right */
    width: 60%; /* Image takes up right side */
    height: 40%; /* Maintain the height ratio */
    background-image: url("../img/pixelcut-export.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 0.5; /* Keep image visibility */
    animation: fadeInZoom 1.5s ease-out; /* Subtle fade-in animation */
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .homepage-hero::before {
        top: 0; /* Position image at the top on mobile */
        right: 0;
        width: 100%; /* Take full width of the screen */
        height: 55%; /* Adjust height on mobile */
        opacity: 0.4; /* Slightly increased opacity for better visibility */
    }
}

/* Desktop view stays the same */
@media (min-width: 769px) {
    .homepage-hero::before {
        top: 20%; /* Keeps it at the top for desktop */
        width: 60%; /* Keeps the width as it is */
        height: 40%; /* Keeps the height for desktop */
    }
}


.hero-content {
    position: relative;
    z-index: 1;
    color: white;
    text-align: left;
    padding-left: 50px; /* Space between content and edge */
    animation: slideInLeft 1.8s ease-out; /* Smooth content slide */
}

.hero-content h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
}

.hero-content p {
    font-size: 1.5rem;
}

/* Animations */
@keyframes fadeInZoom {
    0% {
        opacity: 0;
        transform: scale(1.2);
    }
    100% {
        opacity: 0.5;
        transform: scale(1);
    }
}

@keyframes slideInLeft {
    0% {
        opacity: 0;
        transform: translateX(-50px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.bg-grad-gray {
    background: linear-gradient(59deg, #252525 45.45%, rgba(37, 37, 37, 0.00) 111.69%);
}

.continuous-slider {
    animation: scroll 20s linear infinite;
}

.continuous-slider-after {
    animation: scroll2 20s linear infinite;
    animation-delay: 1s;
    right: 0;
    top: 50%;
    transform: translateX(100%) translateY(-50%);
    width: 100%;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

@keyframes scroll2 {
    0% {
        transform: translateX(100%) translateY(-50%);
    }

    100% {
        transform: translateX(0) translateY(-50%);
    }
}

.track::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 300px;
    height: 100%;
    background: linear-gradient(90deg, #0E0E0F, transparent);
    z-index: 1000;
}

.track::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
    height: 100%;
    background: linear-gradient(90deg, transparent, #0E0E0F);
    z-index: 1000;
}

.solution .card {
    background-image: url("../img/solution-bg.png");
    border: 1px solid #444445;
    background-color: #1A1A1A;
    border-radius: 100px;
    z-index: 1000000;
    position: relative;
    border-image: url("../img/border.png") 100;
    border-image-width: 0 0 20px;
}

.bg-d {
    background-image: url("../img/bg-d.png");
    padding-left: 100px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left;
    padding-top: 150px;
    padding-bottom: 150px;
}

.prism {
    /* background-image: url("../img/prism.png"); */
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
}

.copyright {
    border-radius: 25px;
    border: 1px solid #434343a9;
    background: linear-gradient(59deg, #252525aa 45.45%, rgba(37, 37, 37, 0.00) 111.69%);
}




/* team */
.team-hero {
    background-image: url("../img/team-hero.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top;
}

/* secured*/
.news-hero {
    background-image: url("../img/news-hero.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top;
}

/* contact */
.contact {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../img/contact-bg.png");
    background-size: 100%;
    background-position: top;
    background-repeat: no-repeat;
}

.lh-1 {
    line-height: 1;
}

.contact-card {
    border-radius: 32px;
    border: 1px solid #363636;
    background: rgba(0, 0, 0, 0.5);
    padding: 80px;
    margin-top: 50px;
}

.contact-input {
    border-radius: 10px;
    border: 1px solid #363636;
    background: #1A1A1A;
}

input[type="radio"] {
    display: none;
}

input[type="radio"]+label {
    cursor: pointer;
}

input[type="radio"]:checked+label {
    background-color: white;
    border-color: white;
    color: black;
}

.phase-btn.active .indicator-inner {
    background-color: white;
}

.phase-btn {
    opacity: 0.5;
    cursor: pointer;
}

.phase-btn.active {
    opacity: 1;
}

.phase-btn.active .indicator {
    border-color: white;
}

.secure {
    background-image: url("../img/secure.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
}

.team-img.bnw {
    filter: grayscale(100%);
}


.continuous-slider.relative.flex.justify-between.items-center {
    background: #0e0e0f !important;
    z-index: 10000;
}

.team-img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    object-position: top;
}

.dp img.absolute {
    width: 150px;
    height: 150px;
    object-fit: cover;
    object-position: top;
}

@media screen and (max-width: 767px) {
    .menu-items {
        position: fixed;
        left: 0;
        top: -0;
        border-radius: 0;
        display: flex;
        flex-direction: column;
        width: 75%;
        height: 100vh;
        justify-content: flex-start;
        align-items: flex-start;
        z-index: 1000000;
    }

    .menu-items a {
        padding: 10px;
        background: transparent !important;
    }

    .contact-card {
        margin-top: 20px;
        padding: 24px;
    }

    img.md\:w-14.w-\[50px\].mb-4 {
        min-width: 50px;
    }

    .homepage-hero {
        background-position: center;
        background-size: cover;
        text-align: center;
    }

    .track::before {
        width: 50px;
    }

    .track::after {
        width: 50px;
    }

    .solution .card {
        border-radius: 0;
    }

    .solution-img {
        width: 55px;
    }

    .solution-flex {
        justify-content: flex-start;
    }

    .bg-d {
        padding-left: 0;
        padding-top: 200px;
        padding-bottom: 0;
    }

    .prism {
        background: unset;
    }

    .mobile-social {
        border-radius: 25px;
        border: 1px solid #434343;
        background: linear-gradient(59deg, #252525 45.45%, rgba(37, 37, 37, 0.00) 111.69%);
    }

    .track.relative {
        width: 300%;
    }
}